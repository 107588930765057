import { Observable } from 'rxjs';

export abstract class SfsGateway {
  abstract getBuckets(): Observable<any>;
  abstract getBuckectFiles(moduleName: string): Observable<any>;
  abstract getFileByBucket(
    fileName: string,
    moduleName: string
  ): Observable<any>;
  abstract postBucket(moduleName: string): Observable<any>;
  abstract putFile(file: any, moduleName: string): Observable<any>;
  abstract deleteFile(fileName: string, moduleName: string): Observable<any>;
  abstract generateBucketName(moduleName: string): string;
}
