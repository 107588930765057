export const INIT_SOUMISSIONS_COLOMNS = [
  {
    name: 'select',
    display: '',
  },
  {
    name: 'social_reason',
    display: 'Raison sociale',
  },
  {
    name: 'number',
    display: 'Numéro',
  },
  {
    name: 'kyc',
    display: 'KYC',
  },
  {
    name: 'kyb',
    display: 'KYB',
  },
  {
    name: 'statut',
    display: 'Statut',
    cell: (row: any) => {
      const color = row?.statut !== 'Rejeté' ? 'bg-green' : 'bg-red';
      return `
      <span class="${color}-100 p-2 rounded-full">
      <span class="w-2 h-2 rounded-full ${color}-500 inline-block"></span>
      ${row?.statut}
      </span>
      `;
    },
  },
  {
    name: 'actions',
    display: '	Actions',
  },
];

export const SOUMISSIONS_MOCK_DATA = [
  {
    social_reason: 'HÉVÉA',
    number: '001',
    kyc: 'CLIENT-001',
    kyb: 'ORANGE-CI',
    statut: 'Validé',
  },
  {
    social_reason: 'HÉVÉA',
    number: '001',
    kyc: 'CLIENT-001',
    kyb: 'ORANGE-CI',
    statut: 'Rejeté',
  },
  {
    social_reason: 'HÉVÉA',
    number: '001',
    kyc: 'CLIENT-001',
    kyb: 'ORANGE-CI',
    statut: 'Validé',
  },
  {
    social_reason: 'HÉVÉA',
    number: '001',
    kyc: 'CLIENT-001',
    kyb: 'ORANGE-CI',
    statut: 'Validé',
  },
  {
    social_reason: 'HÉVÉA',
    number: '001',
    kyc: 'CLIENT-001',
    kyb: 'ORANGE-CI',
    statut: 'Validé',
  },
  {
    social_reason: 'HÉVÉA',
    number: '001',
    kyc: 'CLIENT-001',
    kyb: 'ORANGE-CI',
    statut: 'Rejeté',
  },
];
