export const YEARS_NUMBERS = (yearNumber?: number): string[] => {
  const years = [];

  for (let i = 1; i <= (yearNumber ?? 0); i++) {
    years.push(i.toString());
  }
  return years;
};

export const generateFields = (yearNumber: number, baseField: string) =>
  YEARS_NUMBERS(yearNumber).map((_, index) => ({
    fieldName: `${baseField}${index === 0 ? '' : '_' + index}`,
  }));

function generateDataStructure(
  baseField: string,
  yearNumber: number,
  values?: any[]
): { [key: string]: number[] } {
  const fields = generateFields(yearNumber, baseField).map(
    (item) => item.fieldName
  );
  return fields.reduce<{ [key: string]: number[] }>((acc, field) => {
    acc[field] = values ?? [0];
    return acc;
  }, {});
}

export const BILAN_DATA = (yearNumber: number) => [
  {
    title: "CHIFFRE D'AFFAIRE  (XB)",
    field: 'chiffre_affaire_an',
    yearNumbers: generateFields(yearNumber, 'chiffre_affaire_an'),
    disabled: false,
  },
  {
    title: "CROISSANCE DU CHIFFRE D'AFFAIRE",
    field: 'croiss_ca_an',
    yearNumbers: generateFields(yearNumber, 'croiss_ca_an'),
    disabled: true,
  },
  {
    title: 'ACHATS DE MARCHANDISES (RA) ',
    field: 'purchase_of_marchandise_an',
    yearNumbers: generateFields(yearNumber, 'purchase_of_marchandise_an'),
    disabled: false,
  },
  {
    title: 'VARIATIONS DE STOCKS DES MARCHANDISES (RB)',
    field: 'change_in_inventory_of_marchandise_an',
    yearNumbers: generateFields(
      yearNumber,
      'change_in_inventory_of_marchandise_an'
    ),
    disabled: false,
  },
  {
    title: 'ACHATS DE MATIERES PREMIERES (RC)',
    field: 'purchase_of_raw_material_an',
    yearNumbers: generateFields(yearNumber, 'purchase_of_raw_material_an'),
    disabled: false,
  },
  {
    title: 'VARIATIONS DE STOCKS DES MATIERES PREMIERES (RD)',
    field: 'change_in_raw_materials_inventory_an',
    yearNumbers: generateFields(
      yearNumber,
      'change_in_raw_materials_inventory_an'
    ),
    disabled: false,
  },
  {
    title: 'MARGE BENEFICIAIRE BRUTE',
    field: 'gross_profit_margin_an',
    yearNumbers: generateFields(yearNumber, 'gross_profit_margin_an'),
    disabled: true,
  },
  {
    title: 'EBE (XD)',
    field: 'exedent_brut_exploitation_an',
    yearNumbers: generateFields(yearNumber, 'exedent_brut_exploitation_an'),
    disabled: false,
  },
  {
    title: "MARGE D'EBE",
    field: 'gross_operating_margin_an',
    yearNumbers: generateFields(yearNumber, 'gross_operating_margin_an'),
    disabled: true,
  },
  {
    title: "CAPACITE D'AUTOFINANCEMENT GLOBALE (FA)",
    field: 'capacite_autofinancement_an',
    yearNumbers: generateFields(yearNumber, 'capacite_autofinancement_an'),
    disabled: false,
  },
  {
    title: 'CAFG/CA',
    field: 'cafg_ca_an',
    yearNumbers: generateFields(yearNumber, 'cafg_ca_an'),
    disabled: true,
  },
  {
    title: 'RESULTAT NET (XI)',
    field: 'resultat_net_an',
    yearNumbers: generateFields(yearNumber, 'resultat_net_an'),
    disabled: false,
  },
  {
    title: 'MARGE BENEFICIAIRE NETTE',
    field: 'net_profit_margin_an',
    yearNumbers: generateFields(yearNumber, 'net_profit_margin_an'),
    disabled: true,
  },
  {
    title: 'TOTAL TRESORERIE PASSIF (DT)',
    field: 'total_tresorerie_passif_an',
    yearNumbers: generateFields(yearNumber, 'total_tresorerie_passif_an'),
    disabled: false,
  },
  {
    title: 'TOTAL PASSIF CIRCULANT (DP)',
    field: 'passifs_circulants_an',
    yearNumbers: generateFields(yearNumber, 'passifs_circulants_an'),
    disabled: false,
  },
  {
    title: 'TOTAL DETTES FINANCIERES ET RESSOURCES ASSIMILEES (DD)',
    field: 'total_financial_debt_and_similar_resource_an',
    yearNumbers: generateFields(
      yearNumber,
      'total_financial_debt_and_similar_resource_an'
    ),
    disabled: false,
  },
  {
    title: 'DETTES FINANCIERES (DD+DP+DT) / CAFG',
    field: 'dette_financiere_cafg_an',
    yearNumbers: generateFields(yearNumber, 'dette_financiere_cafg_an'),
    disabled: true,
  },
  {
    title: 'TOTAL CAPITAUX PROPRES ET RESSOURCES ASSIMILEES (CP)',
    field: 'total_capitaux_propres_an',
    yearNumbers: generateFields(yearNumber, 'total_capitaux_propres_an'),
    disabled: false,
  },
  {
    title: 'TOTAL ACTIF (BZ)',
    field: 'total_actif_an',
    yearNumbers: generateFields(yearNumber, 'total_actif_an'),
    disabled: false,
  },
  {
    title: 'CAPITAUX PROPRES/ACTIFS (CP/BZ)',
    field: 'cpx_propres_actifs_an',
    yearNumbers: generateFields(yearNumber, 'cpx_propres_actifs_an'),
    disabled: true,
  },
  {
    title: 'FRAIS FINANCIERS ET CHARGES ASSIMILEES (RM)',
    field: 'frais_financiers_an',
    yearNumbers: generateFields(yearNumber, 'frais_financiers_an'),
    disabled: false,
  },
  {
    title: 'FRAIS FINANCIERS / CA',
    field: 'frais_fin_ca_an',
    yearNumbers: generateFields(yearNumber, 'frais_fin_ca_an'),
    disabled: true,
  },
  {
    title: 'TOTAL ACTIF CIRCULANT (BK)',
    field: 'actifs_circulants_an',
    yearNumbers: generateFields(yearNumber, 'actifs_circulants_an'),
    disabled: false,
  },
  {
    title: 'TOTAL TRESORERIE ACTIF (BT)',
    field: 'total_tresorerie_actif_an',
    yearNumbers: generateFields(yearNumber, 'total_tresorerie_actif_an'),
    disabled: false,
  },
  {
    title: 'LIQUIDITE GENERALE (BK+BT)/(DP+DT)',
    field: 'liquidite_gen_an',
    yearNumbers: generateFields(yearNumber, 'liquidite_gen_an'),
    disabled: true,
  },
  {
    title: 'STOCK ET ENCOURS (BB)',
    field: 'inventory_and_work_in_progress_an',
    yearNumbers: generateFields(
      yearNumber,
      'inventory_and_work_in_progress_an'
    ),
    disabled: true,
  },
  {
    title: 'LIQUIDITE IMMEDIATE (BK+BT-BB)/(DP+DT)',
    field: 'immediate_liquidity_an',
    yearNumbers: generateFields(yearNumber, 'immediate_liquidity_an'),
    disabled: true,
  },
  {
    title: 'ECART DE REEVALUATION (CE)',
    field: 'revaluation_surplus_an',
    yearNumbers: generateFields(yearNumber, 'revaluation_surplus_an'),
    disabled: false,
  },
  {
    title: 'DIVIDENDES (FN)',
    field: 'dividend_an',
    yearNumbers: generateFields(yearNumber, 'dividend_an'),
    disabled: false,
  },
  {
    title: 'RATIO  DETTES / CAPITAUX PROPRES ',
    field: 'debt_equity_ratio_an',
    yearNumbers: generateFields(yearNumber, 'debt_equity_ratio_an'),
    disabled: true,
  },
  {
    title: "RATIO D'ENDETTEMENT",
    field: 'debt_ratio_an',
    yearNumbers: generateFields(yearNumber, 'debt_ratio_an'),
    disabled: true,
  },
  {
    title: 'FLUX DE TRESORERIE PROVENANT DES ACTIVITES OPERATIONNELLES (ZB)',
    field: 'operating_cash_flow_an',
    yearNumbers: generateFields(yearNumber, 'operating_cash_flow_an'),
    disabled: false,
  },
  {
    title: 'RATIO DE COUVERTURE DES INTERÊTS',
    field: 'interest_coverage_ratio_an',
    yearNumbers: generateFields(yearNumber, 'interest_coverage_ratio_an'),
    disabled: true,
  },
  {
    title: 'RENDEMENT DES CAPITAUX PROPRES',
    field: 'return_shareholder_equity_an',
    yearNumbers: generateFields(yearNumber, 'return_shareholder_equity_an'),
    disabled: true,
  },
  {
    title: 'RENDEMENT DES ACTIFS',
    field: 'asset_return_an',
    yearNumbers: generateFields(yearNumber, 'asset_return_an'),
    disabled: true,
  },
  {
    title: 'JOURS DE ROTATION DES STOCKS',
    field: 'stock_turnover_days_an',
    yearNumbers: generateFields(yearNumber, 'stock_turnover_days_an'),
    disabled: true,
  },
  {
    title: 'CREANCES CLIENTS ET EMPLOIS ASSIMILE (BG)',
    field: 'trade_receivable_and_similar_item_an',
    yearNumbers: generateFields(
      yearNumber,
      'trade_receivable_and_similar_item_an'
    ),
    disabled: false,
  },
  {
    title: 'JOURS DE CREANCES',
    field: 'days_receivable_an',
    yearNumbers: generateFields(yearNumber, 'days_receivable_an'),
    disabled: true,
  },
  {
    title: 'DETTES FOURNISSEURS (DJ)',
    field: 'supplier_debt_an',
    yearNumbers: generateFields(yearNumber, 'supplier_debt_an'),
    disabled: false,
  },
  {
    title: 'DELAI CREDIT FOURNISSEURS ((DJ*360)/(RA+RB+RC+RD)) (en jours)',
    field: 'supplier_credit_period_an',
    yearNumbers: generateFields(yearNumber, 'supplier_credit_period_an'),
    disabled: true,
  },
  {
    title: 'FRETS ET TRANSPORTS SUR ACHATS (NOTE SUPP. 5 ETATS FINANCIERS)',
    field: 'freight_transport_purchases_an',
    yearNumbers: generateFields(yearNumber, 'freight_transport_purchases_an'),
    disabled: false,
  },
  {
    title: 'TRANSPORTS SUR ACHATS / CA',
    field: 'transport_purchases_sales_an',
    yearNumbers: generateFields(yearNumber, 'transport_purchases_sales_an'),
    disabled: true,
  },
  {
    title: 'TRANSPORTS SUR VENTES (NOTE 23 ETATS FINANCIERS)',
    field: 'transport_sales_an',
    yearNumbers: generateFields(yearNumber, 'transport_sales_an'),
    disabled: false,
  },
  {
    title: 'TRANSPORTS SUR VENTES / CA',
    field: 'transport_sales_revenue_an',
    yearNumbers: generateFields(yearNumber, 'transport_sales_revenue_an'),
    disabled: true,
  },
  {
    title: 'GAINS OU PERTES DE CHANGE (NOTE 29 ETATS FINANCIERS)',
    field: 'foreign_exchange_gains_losses_an',
    yearNumbers: generateFields(yearNumber, 'foreign_exchange_gains_losses_an'),
    disabled: false,
  },
  {
    title: 'GAINS OU PERTE DE CHANGE / CA',
    field: 'foreign_exchange_gains_losses_revenue_an',
    yearNumbers: generateFields(
      yearNumber,
      'foreign_exchange_gains_losses_revenue_an'
    ),
    disabled: true,
  },
];

export function chiffreAffaire(yearnNumber: number) {
  return generateDataStructure('chiffre_affaire_an', yearnNumber);
}
export function croissanceChiffreAffaire(yearnNumber: number) {
  return generateDataStructure('croiss_ca_an', yearnNumber, [
    { value: 0, disabled: true }, // field calculated
  ]);
}
export function achatMarchandises(yearnNumber: number) {
  return generateDataStructure('purchase_of_marchandise_an', yearnNumber);
}
export function variationsStocksMarchandises(yearnNumber: number) {
  return generateDataStructure(
    'change_in_inventory_of_marchandise_an',
    yearnNumber
  );
}
export function achatsMatieresPremieres(yearnNumber: number) {
  return generateDataStructure('purchase_of_raw_material_an', yearnNumber);
}
export function variationsStocksMatieresPremieres(yearnNumber: number) {
  return generateDataStructure(
    'change_in_raw_materials_inventory_an',
    yearnNumber
  );
}
export function margeBeneficiaireBrute(yearnNumber: number) {
  return generateDataStructure(
    'gross_profit_margin_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function ebe(yearnNumber: number) {
  return generateDataStructure('exedent_brut_exploitation_an', yearnNumber);
}
export function margeEBE(yearnNumber: number) {
  return generateDataStructure(
    'gross_operating_margin_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function capaciteAutofinancementGlobale(yearnNumber: number) {
  return generateDataStructure('capacite_autofinancement_an', yearnNumber);
}
export function cafgCa(yearnNumber: number) {
  return generateDataStructure('cafg_ca_an', yearnNumber, [
    { value: 0, disabled: true }, // field calculated
  ]);
}
export function resultatNet(yearnNumber: number) {
  return generateDataStructure('resultat_net_an', yearnNumber);
}
export function margeBeneficiaireNette(yearnNumber: number) {
  return generateDataStructure(
    'net_profit_margin_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function totalTresoreriePassif(yearnNumber: number) {
  return generateDataStructure('total_tresorerie_passif_an', yearnNumber);
}
export function totalPassifCirculant(yearnNumber: number) {
  return generateDataStructure('passifs_circulants_an', yearnNumber);
}
export function totalDettesFinancieres(yearnNumber: number) {
  return generateDataStructure(
    'total_financial_debt_and_similar_resource_an',
    yearnNumber
  );
}
export function totalCapitauxPropesEtRessourceAssimiles(yearnNumber: number) {
  return generateDataStructure('total_capitaux_propres_an', yearnNumber);
}
export function totalActif(yearnNumber: number) {
  return generateDataStructure('total_actif_an', yearnNumber);
}
export function capitauxPropropesEtActifs(yearnNumber: number) {
  return generateDataStructure('cpx_propres_actifs_an', yearnNumber, [
    { value: 0, disabled: true },
  ]);
}
export function fraisFinanceEtChargeAssimile(yearnNumber: number) {
  return generateDataStructure('frais_financiers_an', yearnNumber);
}
export function fraisFinances(yearnNumber: number) {
  return generateDataStructure('frais_fin_ca_an', yearnNumber, [
    { value: 0, disabled: true },
  ]);
}
export function totalActifCirculant(yearnNumber: number) {
  return generateDataStructure('actifs_circulants_an', yearnNumber);
}
export function totalTresorerieActif(yearnNumber: number) {
  return generateDataStructure('total_tresorerie_actif_an', yearnNumber);
}
export function liquiditeGenerale(yearnNumber: number) {
  return generateDataStructure('liquidite_gen_an', yearnNumber, [
    { value: 0, disabled: true }, // field calculated
  ]);
}
export function dettesFinancieresCafg(yearnNumber: number) {
  return generateDataStructure(
    'dette_financiere_cafg_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function stockEtEncours(yearnNumber: number) {
  return generateDataStructure(
    'inventory_and_work_in_progress_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function liquiditeImmediate(yearnNumber: number) {
  return generateDataStructure(
    'immediate_liquidity_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function ecartDeRevaluation(yearnNumber: number) {
  return generateDataStructure('revaluation_surplus_an', yearnNumber);
}
export function dividende(yearnNumber: number) {
  return generateDataStructure('dividend_an', yearnNumber);
}
export function ratioDettesCapitauxPropres(yearnNumber: number) {
  return generateDataStructure(
    'debt_equity_ratio_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function ratioEndettement(yearnNumber: number) {
  return generateDataStructure('debt_ratio_an', yearnNumber, [
    { value: 0, disabled: true }, // field calculated
  ]);
}
export function fluxDeTresorerieProvenantDesActivitesOperationnelles(
  yearnNumber: number
) {
  return generateDataStructure('operating_cash_flow_an', yearnNumber);
}
export function rationDeCouvertureInterets(yearnNumber: number) {
  return generateDataStructure(
    'interest_coverage_ratio_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function rendementCapitauxPropes(yearnNumber: number) {
  return generateDataStructure(
    'return_shareholder_equity_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function rendementDesActifs(yearnNumber: number) {
  return generateDataStructure('asset_return_an', yearnNumber, [
    { value: 0, disabled: true }, // field calculated
  ]);
}
export function joursDeRotatationDesStocks(yearnNumber: number) {
  return generateDataStructure(
    'stock_turnover_days_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function creanceClientEtEmploiAssimile(yearnNumber: number) {
  return generateDataStructure(
    'trade_receivable_and_similar_item_an',
    yearnNumber
  );
}
export function jourDeCreances(yearnNumber: number) {
  return generateDataStructure(
    'days_receivable_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function dettesFournisseurs(yearnNumber: number) {
  return generateDataStructure('supplier_debt_an', yearnNumber);
}
export function delaiCreditFournisseurs(yearnNumber: number) {
  return generateDataStructure(
    'supplier_credit_period_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function fretsEtTransportsSurAchats(yearnNumber: number) {
  return generateDataStructure('freight_transport_purchases_an', yearnNumber);
}
export function transportsSurAchatsSurCA(yearnNumber: number) {
  return generateDataStructure(
    'transport_purchases_sales_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function transportsSurVentes(yearnNumber: number) {
  return generateDataStructure('transport_sales_an', yearnNumber);
}
export function transportsSurVentesSurCA(yearnNumber: number) {
  return generateDataStructure(
    'transport_sales_revenue_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}
export function gainsOuPertesDeChange(yearnNumber: number) {
  return generateDataStructure('foreign_exchange_gains_losses_an', yearnNumber);
}
export function gainsOuPerteDeChangeSurCA(yearnNumber: number) {
  return generateDataStructure(
    'foreign_exchange_gains_losses_revenue_an',
    yearnNumber,
    [{ value: 0, disabled: true }] // field calculated
  );
}

export const THRESHOLDS_CONFIG = {
  '0': {
    color: '#FF4D4D', // Rouge
    bgOpacity: 0.2,
  },
  '20': {
    color: '#FF9533', // Orange
    bgOpacity: 0.2,
  },
  '40': {
    color: '#FFD91A', // Jaune
    bgOpacity: 0.2,
  },
  '60': {
    color: '#C8DF26', // Vert clair
    bgOpacity: 0.2,
  },
  '80': {
    color: '#00BF58', // Vert foncé
    bgOpacity: 0.2,
  },
  '100': {
    color: '#00BF58', // Vert foncé (reprise pour uniformité)
    bgOpacity: 0.2,
  },
};
export const MARKER_CONFIG = {
  '0': {
    color: '#000',
    size: 5,
    type: 'line',
    label: '0%',
  }, // 0
  '2': { color: '#000', size: 10, type: 'line' },
  '4': { color: '#000', size: 10, type: 'line' },
  '6': { color: '#000', size: 10, type: 'line' },
  '8': { color: '#000', size: 10, type: 'line' },
  '10': { color: '#000', size: 5, type: 'line', label: '10%' }, // 10
  '12': { color: '#000', size: 10, type: 'line' },
  '14': { color: '#000', size: 10, type: 'line' },
  '16': { color: '#000', size: 10, type: 'line' },
  '18': { color: '#000', size: 10, type: 'line' },
  '20': { color: '#000', size: 0, type: 'line', label: '20%' }, //20
  '22': { color: '#000', size: 10, type: 'line' },
  '24': { color: '#000', size: 10, type: 'line' },
  '26': { color: '#000', size: 10, type: 'line' },
  '28': { color: '#000', size: 10, type: 'line' },
  '30': { color: '#000', size: 0, type: 'line', label: '30%' }, //30
  '32': { color: '#000', size: 10, type: 'line' },
  '34': { color: '#000', size: 10, type: 'line' },
  '36': { color: '#000', size: 10, type: 'line' },
  '38': { color: '#000', size: 10, type: 'line' },
  '40': { color: '#000', size: 0, type: 'line', label: '40%' }, // 40
  '42': { color: '#000', size: 10, type: 'line' },
  '44': { color: '#000', size: 10, type: 'line' },
  '46': { color: '#000', size: 10, type: 'line' },
  '48': { color: '#000', size: 10, type: 'line' },
  '50': { color: '#000', size: 0, type: 'line', label: '50%' }, // 50
  '52': { color: '#000', size: 10, type: 'line' },
  '54': { color: '#000', size: 10, type: 'line' },
  '56': { color: '#000', size: 10, type: 'line' },
  '58': { color: '#000', size: 10, type: 'line' },
  '60': { color: '#000', size: 0, type: 'line', label: '60%' }, // 60
  '62': { color: '#000', size: 10, type: 'line' },
  '64': { color: '#000', size: 10, type: 'line' },
  '66': { color: '#000', size: 10, type: 'line' },
  '68': { color: '#000', size: 10, type: 'line' },
  '70': { color: '#000', size: 0, type: 'line', label: '70%' }, // 70
  '72': { color: '#000', size: 10, type: 'line' },
  '74': { color: '#000', size: 10, type: 'line' },
  '76': { color: '#000', size: 10, type: 'line' },
  '78': { color: '#000', size: 10, type: 'line' },
  '80': { color: '#000', size: 0, type: 'line', label: '80%' }, // 80
  '82': { color: '#000', size: 10, type: 'line' },
  '84': { color: '#000', size: 10, type: 'line' },
  '86': { color: '#000', size: 10, type: 'line' },
  '88': { color: '#000', size: 10, type: 'line' },
  '90': { color: '#000', size: 0, type: 'line', label: '90%' }, // 90
  '92': { color: '#000', size: 10, type: 'line' },
  '94': { color: '#000', size: 10, type: 'line' },
  '96': { color: '#000', size: 10, type: 'line' },
  '98': { color: '#000', size: 10, type: 'line' },
  '100': { color: '#000', size: 0, type: 'line', label: '100%' }, // 100
};
