import { Observable } from 'rxjs';

export abstract class BilanGateway {
  abstract getPingBilan(): Observable<null>;
  abstract getBilans(): Observable<any>;
  abstract getBilan(bilanID: string): Observable<any>;
  abstract postFindBilan(
    content: Record<any, any>,
    params: Record<string, number>
  ): Observable<any>;
  abstract postBilan(content: Record<any, any>): Observable<any>;
  abstract patchBilan(
    bilanID: string,
    content: Record<any, any>
  ): Observable<any>;
  abstract deleteBilan(bilanID: string): Observable<any>;
}
