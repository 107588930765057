export const MENUS = [
  {
    title: 'Tableau de bord',
    link: '/admin/dashboard',
    icon: 'assets/icons/dashboard.svg',
    disabled: false,
  },
  {
    title: 'Gestion des agents',
    link: '/admin/gestion-agents',
    icon: 'assets/icons/agents.svg',
    disabled: true,
  },
  {
    title: 'Gestion des Clients',
    link: '/admin/gestion-clients',
    icon: 'assets/icons/clients.svg',
    disabled: false,
  },
  {
    title: 'Gestion des soumissions',
    link: '/admin/gestion-soumissions',
    icon: 'assets/icons/soumissions.svg',
    disabled: false,
  },
  {
    title: 'Notifications',
    link: '/admin/notifications',
    icon: 'assets/icons/notifications.svg',
    disabled: true,
  },
  {
    title: 'Rapports',
    link: '/admin/rapports',
    icon: 'assets/icons/rapports.svg',
    disabled: true,
  },
];
