import { Observable } from 'rxjs';

export abstract class KybGateway {
  abstract getKybs(params: Record<string, any>): Observable<any>;
  abstract getKyb(kycID: string): Observable<any>;
  abstract getKybByKycId(params: Record<string, number>): Observable<any>;
  abstract postFindKyb(
    data: Record<any, any>,
    params: Record<string, number>
  ): Observable<any>;
  abstract postCreateKyb(
    data: Record<any, any>,
    options?: any
  ): Observable<any>;
  abstract putKyb(kycID: string, data: Record<string, any>): Observable<any>;
  abstract patchKyb(kycID: string, data: Record<string, any>): Observable<any>;
  abstract deleteKyb(kycID: string): Observable<any>;
}
