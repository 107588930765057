import { Member } from './actionnaire.model';
import { Customer } from './customer.model';
import { Files } from './files.model';
import { Localisation } from './localisation.model';
import { Entrepot } from './entrepot.model';

export enum TypeEntite {
  exploitant = 'exploitant',
  exportateur = 'exportateur',
  transformateur = 'transformateur',
}

export enum TypeAssurance {
  entrepots = 'Assurance sur les entrepots',
  stocks = 'Assurance sur les stocks',
  transports = 'Assurance sur les moyens de transpot',
  civile = 'Assurance sur la responsabilité civile',
  autre = 'Autre',
}

export enum FiliereIntervention {
  cacao = 'Cacao',
  cafe = 'Café',
  noix_de_cajau = 'Noix de Cajou (Anacarde)',
  karite = 'Karité',
  hevea = 'Hévéa',
  cajou = 'Cajou',
  palmier_a_huile = 'Palmier à Huile',
  riz = 'Riz',
  mais = 'Maïs',
  mangue = 'Mangue',
  oignon = 'Oignon',
}

export enum StatutJuridique {
  coop_ca = 'COOP-CA',
  sa = 'SA',
  sarl = 'SARL',
  sas = 'SAS',
  scs = 'SCS',
  snc = 'SNC',
  scoops = 'SCOOPS',
  gie = 'GIE',
}

export interface Kyc {
  customer: Customer;
  abreviation: string;
  adresse_postale: string;
  region_implantation: Localisation[];
  siege_social: Localisation;
  site_internet: string;
  filiere_intervention: FiliereIntervention[];
  date_creation_entite: string;
  statut_juridique: StatutJuridique;
  capital_social: number;
  dfe: Files;
  rccm: Files;
  licence_exploitation: string[];
  certificats: string[];
  actionnaires: Member[];
  responsables: Member[];
  organigramme: string;
  status: string;
  entrepots: Entrepot[];
}

export interface ILocalities {
  [k: string]: {
    localities: { key: string; value: string }[] | [];
    loading: boolean;
  };
}

export type TKycStepStatus = 'save_and_next' | 'save_and_exit';
