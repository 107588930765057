import { FiliereIntervention, StatutJuridique, TSelect } from '@core/models';

export const INIT_CLIENTS_COLOMNS = [
  {
    name: 'select',
    display: '',
  },
  {
    name: 'raison_social',
    display: 'Raison sociale',
    cell: (row: any) =>
      `<span class="uppercase">${row?.customer?.raison_social ?? 'N/A'}</span>`,
  },
  {
    name: 'date_creation_entite',
    display: '	Date de création',
  },
  {
    name: 'statut_juridique',
    display: '	Statut juridique',
  },
  {
    name: 'locality',
    display: '	Localite	',
    cell: (row: any) =>
      `<span class="uppercase">${row?.siege_social?.ville ?? 'N/A'}</span>`,
  },
  {
    name: 'entity_type',
    display: "Type de l'entite",
    cell: (row: any) =>
      `<span class="uppercase">${
        row?.customer?.type_entity?.[0] ?? 'N/A'
      }</span>`,
  },
  {
    name: 'filiere_intervention',
    display: "Filière d'intervention",
  },
  {
    name: 'actions',
    display: '	Actions',
  },
];
export const INIT_ENTREPRISES_COLOMNS = [
  {
    name: 'select',
    display: '',
  },
  {
    name: 'filiere_intervention',
    display: "Filière d'intervention",
    cell: (row: any) =>
      `<span class="uppercase">${row?.filiere_intervention ?? 'N/A'}</span>`,
  },
  {
    name: 'gerant',
    display: 'Gérant',
    cell: (row: any) =>
      `<span class="uppercase">${
        row?.nom_prenom_contact_principal ?? 'N/A'
      }</span>`,
  },
  {
    name: 'email_contact',
    display: 'Adresse E-mail',
    cell: (row: any) =>
      `<span class="uppercase">${row?.email_contact ?? 'N/A'}</span>`,
  },
  {
    name: 'numero_contact',
    display: '	Contact	',
    cell: (row: any) =>
      `<span class="uppercase">${row?.numero_contact ?? 'N/A'}</span>`,
  },
  {
    name: 'actions',
    display: 'Actions',
  },
];
export const MOCK_CLIENTS_DATA = [
  {
    _id: '001',
    social_reason: 'ORANGE-CI',
    creation_date: '10/10/2000',
    juridic_statut: 'SAS',
    locality: 'TONKPI - MAN	',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'HÉVÉA	',
  },
  {
    _id: '002',
    social_reason: 'MOOV-CI',
    creation_date: '10/10/2001',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - ZAGNE	',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'CACAO	',
  },
  {
    _id: '003',
    social_reason: 'KOZ-CI',
    creation_date: '10/10/2002',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - ZAGNE	',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'COTON	',
  },
  {
    _id: '004',
    social_reason: 'MTN-CI',
    creation_date: '10/10/2003',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - GUIGLO		',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'PALMIER A HUILE	',
  },
  {
    _id: '005',
    social_reason: 'MTN-CI',
    creation_date: '10/10/2003',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - GUIGLO		',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'PALMIER A HUILE	',
  },
  {
    _id: '006',
    social_reason: 'MTN-CI',
    creation_date: '10/10/2003',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - GUIGLO		',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'PALMIER A HUILE	',
  },
  {
    _id: '007',
    social_reason: 'MTN-CI',
    creation_date: '10/10/2003',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - GUIGLO		',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'PALMIER A HUILE	',
  },
  {
    _id: '001',
    social_reason: 'MTN-CI',
    creation_date: '10/10/2003',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - GUIGLO		',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'PALMIER A HUILE	',
  },
  {
    _id: '001',
    social_reason: 'MTN-CI',
    creation_date: '10/10/2003',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - GUIGLO		',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'PALMIER A HUILE	',
  },
  {
    _id: '001',
    social_reason: 'MTN-CI',
    creation_date: '10/10/2003',
    juridic_statut: 'SAS',
    locality: 'CAVALLY - GUIGLO		',
    entity_type: 'COOPERATIVE	',
    filiere_intervention: 'PALMIER A HUILE	',
  },
];
export const MOCK_ENTREPRISES_DATA = [
  {
    filiere_intervention: 'HÉVÉA',
    entrepriseID: '001 - 001',
    clientID: '001',
    gerant: 'ORANGE-CI',
    email: 'contact@gmail.com',
    contact: '00-00-00-00-00',
  },
  {
    filiere_intervention: 'HÉVÉA',
    entrepriseID: '001 - 001',
    clientID: '001',
    gerant: 'ORANGE-CI',
    email: 'contact@gmail.com',
    contact: '00-00-00-00-00',
  },
  {
    filiere_intervention: 'HÉVÉA',
    entrepriseID: '001 - 001',
    clientID: '001',
    gerant: 'ORANGE-CI',
    email: 'contact@gmail.com',
    contact: '00-00-00-00-00',
  },
];
export const BANK_HEADERS = [
  'Banque',
  'Type de prêts',
  'Date',
  'Durée (mois)',
  'Montant total (FCFA)',
  'Montant remboursement',
  'Ligne bancaire',
  'Si oui, Hauteur',
];
export const FILIERE_INTERVENTIONS: string[] =
  Object.values(FiliereIntervention);
export const TYPE_ENTITES: TSelect[] = [
  { label: 'Exploitant', value: 'exploitant' },
  { label: 'Exportateur', value: 'exportateur' },
  { label: 'Transformateur', value: 'transformateur' },
];
export const STATUT_JURIDIQUES: String[] = Object.values(StatutJuridique);

export const URL_PATTERN = new RegExp(
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/
);
export const ACCEPTED_FILES = '.pdf,.xlsx,.docx,.jpg,.jpeg,.png';

export const ORGANE_DE_GOUVERNANCE: Record<StatutJuridique, string[]> = {
  [StatutJuridique.coop_ca]: [
    'Assemblée Générale',
    "Conseil d'Administration",
    "Président du Conseil d'Administration",
    'Conseil de Surveillance (CS)',
    'Directeur',
  ],
  [StatutJuridique.sa]: [
    'Assemblée Générale des Actionnaires',
    "Conseil d'Administration",
    'Comité de direction',
    'Directeur Général',
  ],
  [StatutJuridique.sarl]: [
    'Assemblée Générale des Associés',
    'Membres dirigeants',
    'Gérant',
  ],
  [StatutJuridique.sas]: [
    'Assemblée Générale des Actionnaires',
    "Conseil d'Administration ou comité de direction",
    'Directeur général ou directeurs généraux délégués',
  ],
  [StatutJuridique.scs]: [
    'Assemblée des Associés',
    'Commanditaires',
    'Commandités',
  ],
  [StatutJuridique.snc]: ['Associés Gérants', 'Assemblée des Associés'],
  [StatutJuridique.scoops]: [
    'Assemblée Générale (AG)',
    'Comité de Gestion',
    'Conseil de Surveillance',
    'Directeur',
    "Comité de Crédit (pour les coopératives d'épargne et de crédit)",
  ],
  [StatutJuridique.gie]: [
    'Assemblée Générale des Membres',
    'Administrateur ou Comité de Gestion',
  ],
};

export const COUNT_MEMBERS = [
  'Moins de 25%',
  '25-49%',
  '50-74%',
  'Plus de 75%',
];
export const WOMAN_COUNT_MEMBERS = ['0-9%', '10-24%', '25-49%', '50-100%'];

export const MODALITES = [
  'Quelques initiatives avec impact limité',
  'Initiative en place avec impact modéré',
  'Initiatives bien définies avec impact significatif',
];

export const AUDIT_EXTERNES = [
  "Pas d'audits externes ou états financiers non visés",
  'Audits avec réserves significatives',
  'Audits avec quelques réserves mineures ou états financiers visés',
  'Audits sans réserves et conformité locale',
];

export const NOMBRE_DE_MENBRES_AVEC_DOMICILIATIONS_BANCAIRES = [
  'Moins de 25%',
  'Compris entre 25 et 50% exclu',
  'Compris entre 50 et 75% exclu',
  'Compris entre 75 et 100% exclu',
  'Égal à 100%',
];
