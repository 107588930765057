import { Observable } from 'rxjs';

export abstract class CreditScoringGateway {
  abstract getPingCsr(): Observable<any>;
  abstract getCsrs(): Observable<any>;
  abstract getCsr(csrID: string): Observable<any>;
  abstract postFindCsr(
    data: Record<any, any>,
    params: Record<string, any>
  ): Observable<any>;
  abstract postCreateCsr(data: Record<any, any>): Observable<any>;
  abstract deleteCsr(csrID: string): Observable<any>;
}
