import { Observable } from 'rxjs';

export abstract class KycGateway {
  abstract getKycs(params: Record<string, number>): Observable<any>;
  abstract getKyc(kycID: string): Observable<any>;
  abstract postFindKyc(data: any): Observable<any>;
  abstract postCreateKyc(data: Record<any, any>): Observable<any>;
  abstract putKyc(kycID: string, data: Record<any, any>): Observable<any>;
  abstract patchKyc(kycID: string, data: Record<any, any>): Observable<any>;
  abstract deleteKyc(kycID: string): Observable<any>;
  abstract getFiliereInterventionByKycId(kycID: string): Observable<any>;
  abstract getVerifyIsSocialRaisonExists(
    raison_social: string,
    ville: string
  ): Observable<any>;
}
