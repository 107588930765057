import { Observable } from 'rxjs';

import { Auth, CreateUser } from '@core/models/auth.model';

export abstract class AuthGateway {
  abstract login(credentials: CreateUser): Observable<Auth>;
  abstract logout(): Promise<boolean>;
  abstract get isLoggedIn(): boolean;
  abstract get accessToken(): string;
  abstract get connectedUser(): any;
  abstract getChecksIfUserAlreadyExists(): Observable<any>;
}
